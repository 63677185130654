<template>
  <h2 class="page-title">Account</h2>
  <Loader v-if="isLoading" />
  <div v-if="success" class="message message-success">Your account has been updated.</div>
  <form v-if="!isLoading" v-on:submit.prevent="update" novalidate>
    <p class="message message-error" v-if="error">{{ error }}</p>
    <div id="profile" v-if="!cropping">
      <div class="profile-image-wrap">
        <img :src="profileImage" alt="Profile Image" @click="$refs.fileInput.click()">
        <span class="icon icon-edit" @click="$refs.fileInput.click()">Edit</span>
      </div>
      <input ref="fileInput" type="file" accept="image/*" style="display: none;" @change="onFileSelect" />
    </div>
    <div class="modal" v-show="cropping">
      <div id="crop-canvas">
        <vue-cropper ref="cropper" :src="selectedFile" :view-mode="3" :aspect-ratio="1" :movable="false" :zoomable="false"></vue-cropper>
      </div>
      <div class="actions">
        <span class="btn" v-on:click="saveImage">Save</span>
        <span class="btn" v-on:click="cancelImage">Cancel</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-input">
        <label for="password">Enter current password to make profile updates.</label>
        <input id="password" type="password" v-model="password" placeholder="Current Password">
      </div>
      <div class="form-input">
        <label for="username">Username</label>
        <input id="username" type="text" v-model="username" placeholder="Enter Username" v-bind:class="[usernameError ? 'error' : '']" :disabled="!passwordEntered">
        <div class="form-error" v-if="usernameError">{{ usernameError }}</div>
      </div>
      <div class="form-input">
        <label for="email">Email</label>
        <input id="email" type="email" v-model="email" placeholder="Enter Email" v-bind:class="[emailError ? 'error' : '']" :disabled="!passwordEntered">
        <div class="form-error" v-if="emailError">{{ emailError }}</div>
      </div>
      <div class="form-input">
        <label for="password">Change Password</label>
        <input type="password" v-model="newPassword" placeholder="New Password" v-bind:class="[passwordError ? 'error' : '']" :disabled="!passwordEntered">
        <div class="form-error" v-if="passwordError">{{ passwordError }}</div>
      </div>
    </div>
    <div class="form-input form-actions">
      <button class="btn" type="submit" :disabled="!passwordEntered">Update</button>
      <span class="btn-secondary" v-on:click="logout">Logout</span>
    </div>
  </form>

</template>

<script>
import Loader from '@/components/Loader.vue'
import axios from 'axios'
import AuthService from '../services/auth.service'
import authHeader from '../services/auth-header'
import validator from '../services/validator'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'Account',
  components: {
    Loader,
    VueCropper
  },
  data () {
    return {
      isLoading: true,
      profileImage: '',
      selectedFile: '',
      mimeType: '',
      cropping: false,
      error: '',
      username: '',
      usernameError: '',
      email: '',
      emailError: '',
      password: '',
      newPassword: '',
      passwordError: '',
      success: false
    }
  },
  computed: {
    passwordEntered () {
      return !validator.isEmpty(this.password) && validator.isValidPassword(this.password)
    }
  },
  created () {
    this.getAccount()
    this.success = false
  },
  methods: {
    getAccount: function () {
      axios
        .get(API_URL + '/profile', {
          headers: authHeader()
        })
        .then(response => {
          this.isLoading = false
          if (response.data.email) {
            this.username = response.data.username
            this.email = response.data.email
            this.profileImage = API_URL + response.data.image
          } else if (response.data.error) {
            this.logout()
          }
        })
        .catch(error => {
          console.log(error)
          this.logout()
        })
    },
    // @todo This function is used in Signup as well, need to abstract it.
    validate: function () {
      this.error = ''
      this.usernameError = ''
      this.emailError = ''
      this.passwordError = ''
      if (validator.isEmpty(this.password)) {
        this.passwordError = 'Current password is required to make updates.'
        return false
      }
      if (validator.isEmpty(this.username)) {
        this.usernameError = 'The username field is required.'
        return false
      }
      if (!validator.isValidUsername(this.username)) {
        this.usernameError = 'Usernames may only contain lowercase letters, numbers and dashes (-).'
        return false
      }
      if (validator.isEmpty(this.email)) {
        this.emailError = 'The email field is required.'
        return false
      }
      if (!validator.isValidEmail(this.email)) {
        this.emailError = 'Email is not valid.'
        return false
      }
      if (!validator.isEmpty(this.newPassword)) {
        if (!validator.isValidPassword(this.newPassword)) {
          this.passwordError = 'The new password must be at least 8 characters long.'
          return false
        }
      }
      return true
    },
    update: function () {
      if (this.validate()) {
        AuthService
          .update({
            username: this.username,
            email: this.email,
            password: this.password,
            newPassword: this.newPassword
          })
          .then(response => {
            if (response.token) {
              this.success = true
              this.password = ''
              this.newPassword = ''
            } else {
              this.success = false
            }
            if (response.errors) {
              if (response.status === 401) {
                this.error = 'Incorrect password.'
              }
              if (response.errors.username) {
                this.usernameError = response.errors.username.join(' ')
              }
              if (response.errors.email) {
                this.emailError = response.errors.email.join(' ')
              }
              if (response.errors.password) {
                this.passwordError = response.errors.password.join(' ')
              }
            }
            window.scrollTo(0, 0)
          })
      }
    },
    logout: function () {
      AuthService.logout()
      this.$router.push('/')
    },
    saveImage () {
      // this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData()
        formData.append('image', blob)
        formData.append('type', this.mimeType)
        axios
          .post(API_URL + '/profile/image', formData, {
            headers: authHeader()
          })
          .then(response => {
            this.profileImage = API_URL + response.data.path
            this.cropping = false
            const user = JSON.parse(localStorage.getItem('user'))
            user.image = response.data.path
            AuthService.persistUser(user)
          })
          .catch(error => {
            console.log(error)
          })
      }, this.mimeType)
    },
    cancelImage () {
      this.cropping = false
    },
    onFileSelect (e) {
      const file = e.target.files[0]
      this.mimeType = file.type
      if (typeof FileReader === 'function') {
        this.cropping = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    }
  }
}
</script>

<style scoped lang="sass">
#profile
  max-width: 300px
  margin: 0 auto 20px
.profile-image-wrap
  max-width: 150px
  margin: 0 auto
  position: relative
  img
    cursor: pointer
    border-radius: 50%
.icon
  width: 32px
  height: 0
  padding-top: 32px
  overflow: hidden
  display: block
  background-repeat: no-repeat
  background-position: center center
  cursor: pointer
  position: absolute
  top: 60%
  right: 0
  background-color: #fff
  border-radius: 50%
  box-shadow: 0 0 5px rgba(0,0,0,0.25)
.icon-edit
  background-image: url('../assets/icon-photo.svg')
#crop-canvas
  max-width: 300px
  margin: 0 auto 20px
</style>
